import SourceFlowText from "@sourceflow-uk/sourceflowtext"
import SourceFlowImage from "@sourceflow-uk/sourceflowimage"
import {
    Container,
    Row,
    Col,
    Card
} from 'reactstrap';
import styles from './styles.module.scss';
import Link from "next/link"
import SocialLinks from '/components/SocialLinks';
import SimpleForm from '/components/SimpleForm';
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
import imagesMetaData from '@/.sourceflow/image_metadata.json';

export default function ImageWithTextWithOverlay({
    global,
    pathPrefix,
    customClass = "",
    content,
    buttonTitle,
    buttonLink,
    buttonNewTab,
    buttonTitle2,
    buttonLink2,
    imageURL,
    imageALT,
    popUpModal_1,
    dialog_content_1,
    popUpModal_1_form_id,
    successPageUrl,
    backgroundColor,
    rowClass,
}) {
    Fancybox.bind("[data-src]", {
        // Your custom options
    });
    return (
        <section className={`${styles.root} ${customClass}` } style={{background: backgroundColor}}>
            <Container>
                <Row className={`align-items-center ${rowClass}`}>
                    <Col md={5} className="left-column pe-md-5 mb-5 mb-md-0">
                        <SourceFlowImage
                            src={imageURL ? imageURL : "/site-assets/placeholders/img-placeholder.jpg"}
                            size="1000x"
                            width="100%"
                            height="100%"
                            alt={imageALT}
                            className="card-img rounded-4"
                            imagesMetaData={imagesMetaData}
                            path={pathPrefix ? pathPrefix : (`${pathPrefix}.image`) }
                        />
                    </Col>
                    <Col md={7} className="right-column ps-md-5">
                        {content ?
                            <div className="mb-4">
                                <SourceFlowText global={global} path={`${pathPrefix}.content`} type="html">
                                    {content}
                                </SourceFlowText>
                            </div> : null}
                        {popUpModal_1 ?
                            <>
                                {buttonTitle2 &&
                                    <Link href={`${buttonLink2}`}>
                                        <a className="primaryBtn no-arrow">
                                            {buttonTitle2}
                                        </a>
                                    </Link>
                                }
                                <button className="border-0 fancyBox primaryBtn no-arrow" data-src={`#dialog-content-1`}>
                                    {buttonTitle}
                                </button>
                            </> : buttonLink ?
                                <div>
                                    <Link href={`${buttonLink}`}>
                                        <a className="primaryBtn no-arrow" target={buttonNewTab ? "_blank" : "_self"}>
                                            {buttonTitle}
                                        </a>
                                    </Link>
                                    {buttonTitle2 &&
                                        <Link href={`${buttonLink2}`}>
                                            <a className="primaryBtn no-arrow">
                                                {buttonTitle2}
                                            </a>
                                        </Link>
                                    }
                                </div> : null
                        }
                        {/* // {buttonLink ?
                        //     <div>
                        //         <Link href={`${buttonLink}`}>
                        //             <a className="primaryBtn no-arrow">
                        //                 {buttonTitle}
                        //             </a>
                        //         </Link>
                        //     </div> : null} */}
                        {popUpModal_1_form_id ?
                            <div class="dialog-content" id="dialog-content-1">
                                <div>
                                    {dialog_content_1 ?
                                        <>
                                            <SourceFlowText global={global} path={`${pathPrefix}.dialog-content-1`} type="html">
                                                {dialog_content_1}
                                            </SourceFlowText> <hr></hr>
                                        </> : null}

                                    <SimpleForm
                                        formId={`${popUpModal_1_form_id}`}
                                        successPageUrl={`${successPageUrl}`}
                                    />
                                </div>
                            </div> : null}
                    </Col>
                </Row>
            </Container >
        </section >
    )
}